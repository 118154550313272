import { useTranslation } from "react-i18next";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";

export default function MembersUpdateHeader({
  heading,
  label,
  routeIndex,
  routeView,
}) {
  const { t } = useTranslation();

  return (
    <Breadcrumbs
      heading={heading}
      links={[
        {
          name: heading,
          to: routeIndex,
        },
        { name: label, to: routeView },
        {
          name: t("Edit"),
        },
      ]}
      sx={{ mb: { xs: 3, md: 5 } }}
    />
  );
}
