import GuestSignupForm from "./components/GuestSignupForm.react";
import { useNavigate } from "react-router-dom";

export default function GuestEditPage({ data }) {
  const navigate = useNavigate();

  return (
    <GuestSignupForm
      data={data}
      isPublic={false}
      onSuccess={() => {
        // TODO: migrate guest complete form to route
        navigate("/");
        window.location.reload();
      }}
    />
  );
}
