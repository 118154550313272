import fetch from "data/fetch";
import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";

function fetchFamiliaCristaMembers(id) {
  return Promise.all([
    fetch(`/v1/familia-crista/${id}/membros`),
    fetch(`/v1/familia-crista-relation`)
  ]).then(([{ data: members }, { data: allRelations }]) => {
    // TODO: this is hacky... loading all relations and finding things on the
    // client-side
    const relationsForThisFamiliaCrista = allRelations.filter(
      (x) => x.familiaCrista.id === id
    );

    const findMemberRelation = (memberId) =>
      relationsForThisFamiliaCrista.find((x) => x.pessoa.id === memberId);

    return {
      data: members.map((m, i) => ({
        ...m,
        relationId: findMemberRelation(m.id).id,
      })),
    };
  });
}

const getQueryKey = (id) => ["familia-crista", id, "members"];

export function useFamiliaCristaMembersQuery({ id, ...other }) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(id),
    queryFn: () => fetchFamiliaCristaMembers(id),
  });
}

export function ensureFamiliaCristaMembersQueryData(id) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(id),
    queryFn: () => fetchFamiliaCristaMembers(id),
  });
}

export function invalidateFamiliaCristaMembersQuery(id) {
  return queryClient.invalidateQueries(["familia-crista", id, "members"]);
}
