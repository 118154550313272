import React from "react";
import { useTranslation } from "react-i18next";
import {
  useFamiliaCristaFilesQuery,
  ensureFamiliaCristaFilesQueryData,
} from "data/queries/queryFamiliaCristaFiles";
import IndexPageHeader from "shared/layouts/index/IndexPageHeader.react";
import IndexPageLayout from "shared/layouts/index/IndexPageLayout.react";
import IndexPageTable from "shared/layouts/index/IndexPageTable.react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { apiPrefix } from "config/api-prefix";

export async function loader() {
  return await ensureFamiliaCristaFilesQueryData();
}

export default function FamiliaCristaFilePage() {
  const { t } = useTranslation();

  return (
    <IndexPageLayout
      useQuery={useFamiliaCristaFilesQuery}
      hasSearch={false}
      header={
        <IndexPageHeader
          heading={t("Familia Crista Files")}
          routeIndex="/familia-crista/files"
          routeNew="/familia-crista/upload-file"
        />
      }
      table={
        <IndexPageTable
          headers={[t("File ID"), t("Type"), t("Description"), t("Study ID")]}
          renderRow={({ id, fileId, type, description, estudoId }) => (
            <TableRow hover key={id} tabIndex={-1}>
              <TableCell>
                <Link
                  href={apiPrefix(`/v1/file/${fileId}`)}
                  download={`familia_crista_${type}_${estudoId}_${fileId}`}
                >
                  <Typography variant="subtitle2" noWrap>
                    {fileId}
                  </Typography>
                </Link>
              </TableCell>
              <TableCell>{type}</TableCell>
              <TableCell>{description}</TableCell>
              <TableCell>{estudoId}</TableCell>
            </TableRow>
          )}
        />
      }
    />
  );
}
