import { redirect } from "react-router-dom";
import { createAuth0Client } from "@auth0/auth0-spa-js";
import { getAuthConfig } from "config/auth-config";

const {
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  audience: AUTH0_AUDIENCE,
} = getAuthConfig();

const _authState = {
  accessToken: null,
  auth0Client: null,
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const REDIRECT_PATHNAME = "/login-callback";
const REDIRECT_URI = `${window.location.origin}${REDIRECT_PATHNAME}`;
const CODE_RE = /[?&]code=[^&]+/;
const STATE_RE = /[?&]state=[^&]+/;
const ERROR_RE = /[?&]error=[^&]+/;

export function protectedLoader(loader) {
  return async (params) => {
    await _initialize();

    if (_authState.isAuthenticated) {
      return loader(params);
    }

    const { pathname, search, hash } = window.location;
    const returnTo = `${pathname}${search}${hash}`;

    return redirect(`/login?returnTo=${encodeURIComponent(returnTo)}`);
  };
}

export async function alreadyLoggedInLoader() {
  await _initialize();
  return _authState.isAuthenticated ? redirect("/") : null;
}

export async function loginCallbackLoader() {
  const appState = await _initialize();
  return redirect(appState?.returnTo ?? "/");
}

export async function login(returnTo) {
  await _initialize();

  return await _authState.auth0Client.loginWithRedirect({
    appState: { returnTo },
    authorizationParams: {
      audience: AUTH0_AUDIENCE,
      redirect_uri: REDIRECT_URI,
    },
  });
}

export function logout() {
  _throwIfUnitialized();
  _authState.auth0Client.logout();
}

export function getUser() {
  if (!_authState.isInitialized) {
    return null;
  }
  return _authState.user;
}

export function getAccessToken() {
  return _authState.accessToken;
}

function _hasAuthParams(searchParams = window.location.search) {
  return (
    (CODE_RE.test(searchParams) || ERROR_RE.test(searchParams)) &&
    STATE_RE.test(searchParams)
  );
}

export function _throwIfUnitialized() {
  if (!_authState.isInitialized) {
    console.warn("Auth is not initialized.");
    return false;
  }
  return true;
}

let initializePromise;

function _initialize() {
  if (!initializePromise) {
    initializePromise = _initializeImpl();
  }

  return initializePromise;
}

async function _initializeImpl() {
  if (_authState.isInitialized) {
    throw new Error("Auth has been initialized already");
  }

  const auth0Client = await createAuth0Client({
    domain: AUTH0_DOMAIN,
    clientId: AUTH0_CLIENT_ID,
    authorizationParams: {
      audience: AUTH0_AUDIENCE,
    },
  });

  let appState;

  const isCallbackRoute =
    window.location.pathname.indexOf(REDIRECT_PATHNAME) !== -1;

  if (isCallbackRoute && _hasAuthParams()) {
    const result = await auth0Client.handleRedirectCallback();
    appState = result.appState;
  }

  const user = await auth0Client.getUser();

  _authState.auth0Client = auth0Client;
  _authState.user = user;
  _authState.isInitialized = true;

  if (user != null) {
    _authState.isAuthenticated = true;
    _authState.accessToken = await auth0Client.getTokenSilently();
  }

  return appState;
}

export { _authState };