import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

export default function LoadingTableRow({ rows = 14, cells = 1 }) {
  return [...Array(rows)].map((_i, i) => (
    <TableRow key={i}>
      {[...Array(cells)].map((_j, j) => (
        <TableCell key={j}>
          <Typography variant="subtitle2" noWrap>
            <Skeleton />
          </Typography>
        </TableCell>
      ))}
    </TableRow>
  ));
}
