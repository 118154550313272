import { useTranslation } from "react-i18next";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import fetch from "data/fetch";
import LinearProgress from "@mui/material/LinearProgress";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import PessoaSelect from "shared/components/PeopleSelect/PeopleSelect.react";

export default function AdminIndexPage() {
  return (
    <Container maxWidth="sm">
      <Stack spacing={4}>
        <Card>
          <CardHeader title="Criar Usuário" />
          <CardContent as={Stack} spacing={3}></CardContent>
        </Card>
        <FetchUserCard />
        <Card>
          <CardHeader title="Deletar Usuário" />
          <CardContent as={Stack} spacing={3}></CardContent>
        </Card>
        <Card>
          <CardHeader title="Buscar Funções" />
          <CardContent as={Stack} spacing={3}></CardContent>
        </Card>
        <Card>
          <CardHeader title="Criar Função" />
          <CardContent as={Stack} spacing={3}></CardContent>
        </Card>
        <RoleAssociationCard />
        <Card>
          <CardHeader title="Listar Serviços" />
          <CardContent as={Stack} spacing={3}></CardContent>
        </Card>
      </Stack>
    </Container>
  );
}

function FetchUserCard() {
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);
    fetch(`/v1/pessoa?name=${name ?? ""}`)
      .then((res) => {
        setData(res);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Card
      component="form"
      method="submit"
      autoComplete="off"
      onSubmit={onSubmit}
    >
      <CardHeader
        title="Buscar Pessoa"
        subheader="Deixe o campo abaixo vazio para buscar todas pessoas."
      />
      <CardContent as={Stack} spacing={3}>
        <Stack direction="row">
          <TextField
            name="name"
            type="text"
            sx={{ flexGrow: 1 }}
            label={"Nome"}
            value={name}
            onChange={(e) => {
              setName(e.currentTarget.value);
            }}
          />
          <IconButton type="submit">
            <SearchIcon />
          </IconButton>
        </Stack>
        {isLoading && <LinearProgress />}
        {data && <Code data={data} />}
      </CardContent>
    </Card>
  );
}

function RoleAssociationCard() {
  const { t } = useTranslation();
  const [type, setType] = useState("CONVIDADO");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [person, setPerson] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();

    if (person == null || type == null || type === "") {
      return;
    }

    setIsLoading(true);

    fetch("/v1/pessoa", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        address: person.address,
        birthDate: person.birthDate,
        congregacaoId: person.congregacao.id,
        creationStatus: person.creationStatus,
        email: person.email,
        maritalStatus: person.maritalStatus,
        name: person.name,
        phone: person.phone,
        tipo: type,
        waterBaptismAt: person.waterBaptismAt,
      }),
    })
      .then((res) => {
        setData(res);
      })
      .catch((error) => {
        setData({
          message: error.message,
          error,
        });

        if (error.body) {
          Promise.resolve(error.body).then((body) => {
            if (body != null) {
              setData({
                body,
                message: error.message,
                error,
              });
            }
          });
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Card
      component="form"
      method="submit"
      autoComplete="off"
      onSubmit={onSubmit}
    >
      <CardHeader title="Associar função a usuário" />
      <CardContent as={Stack} spacing={3}>
        <PessoaSelect onChange={setPerson} />
        <FormControl>
          <InputLabel>{t("Função")}</InputLabel>
          <Select value={type} onChange={(e) => setType(e.target.value)}>
            <MenuItem value="CONVDADO">{t("Convidado")}</MenuItem>
            <MenuItem value="CONGREGADO">{t("Congregado")}</MenuItem>
            <MenuItem value="MEMBRO">{t("Membro")}</MenuItem>
            <MenuItem value="COOPERADOR">{t("Cooperador")}</MenuItem>
            <MenuItem value="LIDER">{t("Líder")}</MenuItem>
            <MenuItem value="DIACONO">{t("Diácono")}</MenuItem>
            <MenuItem value="PRESBITERO">{t("Presbítero")}</MenuItem>
            <MenuItem value="EVANGELISTA">{t("Evangelista")}</MenuItem>
            <MenuItem value="PASTOR">{t("Pastor")}</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          size="large"
          disabled={isLoading}
          type="submit"
        >
          {t("Save")}
        </Button>
        {isLoading && <LinearProgress />}
        {data && <Code data={data} />}
      </CardContent>
    </Card>
  );
}

function Code({ data, children }) {
  return (
    <pre
      style={{
        overflow: "auto",
        fontSize: "11px",
        backgroundColor: "#EEE",
        borderRadius: "8px",
        padding: "4px",
        maxHeight: 400,
      }}
    >
      {data && JSON.stringify(data, null, 4)}
      {children}
    </pre>
  );
}
