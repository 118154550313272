import { QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Allow all queries to be stalled for 20s max
      staleTime: 1000 * 20,
    },
  },
});

export default queryClient;
