import { useMutation } from "@tanstack/react-query";
import fetch from "data/fetch";
import { invalidateCaringGroupsReportsQuery } from "data/queries/queryCaringGroupsReports";

export function useCaringGroupReportDeleteMutation({ onSuccess }) {
  return useMutation({
    mutationFn({ id }) {
      return fetch(`/v1/caring-groups-relatorio/${id}`, { method: "DELETE" });
    },
    onSuccess(_, { id }) {
      invalidateCaringGroupsReportsQuery();
      onSuccess?.();
    },
  });
}
