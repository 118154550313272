import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

function fetchCaringGroups(page = 0, filter = "") {
  return fetch(
    `/v1/caring-groups?size=100&index=${page}&q=${encodeURIComponent(filter)}`
  );
}
const getQueryKey = (index = 0, name = "") => ["caring-groups", index, name];

export function useCaringGroupsQuery({ page = 0, filter = "", ...other }) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchCaringGroups(page, filter),
  });
}

export function ensureCaringGroupsQueryData({ page = 0, filter = "" } = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchCaringGroups(page, filter),
  });
}

export function invalidateCaringGroupsQuery() {
  return queryClient.invalidateQueries(["caring-groups"]);
}
