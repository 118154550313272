import { ensureCongregationQueryData } from "data/queries/queryCongregations";
import GuestSignupForm from "./components/GuestSignupForm.react";

export async function loader() {
  // preload congregations
  await ensureCongregationQueryData();
  return null;
}

export default function GuestNewPage() {
  return <GuestSignupForm isPublic={false} />;
}
