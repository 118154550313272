import { Link as RouterLink, Form, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import LogoWordmark from "shared/components/Logo/LogoWordmark.react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as Auth from "shared/auth/Auth";

export async function action({ request }) {
  const formData = await request.formData();

  await Auth.login(formData.get("returnTo"));

  return null;
}

export default function LoginPage() {
  return (
    <Container component="main" fixed sx={styles.container}>
      <LogoWordmark sx={styles.logo} />
      <Stack direction="column" spacing={2}>
        <LoginButton />
        <GuestSignupButton />
      </Stack>
      <ReservedRightsFooter />
    </Container>
  );
}

function LoginButton() {
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const returnTo = decodeURIComponent(params.get("returnTo") ?? "/");

  return (
    <Box component={Form} sx={styles.links} method="post" replace>
      <input type="hidden" name="returnTo" value={returnTo} />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        startIcon={<GroupAddIcon />}
      >
        {t("Login")}
      </Button>
    </Box>
  );
}

function GuestSignupButton() {
  const { t } = useTranslation();

  return (
    <Button
      fullWidth
      variant="outlined"
      startIcon={<GroupAddIcon />}
      component={RouterLink}
      to="/convite"
    >
      {t("Add Guests")}
    </Button>
  );
}

function ReservedRightsFooter() {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={styles.copyright}
    >
      {"Copyright © " + new Date().getFullYear() + " "}
      Assembly of God Bethlehem Ministry
      <br />
      All Rights Reserved
    </Typography>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100%",
  },
  logo: {
    width: [300, 370],
    mb: 2,
  },
  form: {
    flex: 1,
  },
  links: {
    flex: 1,
    display: "flex",
    alignItems: "center",
  },
  divider: {
    mx: [0, 3],
    display: ["none", "block"],
  },
  copyright: {
    mt: 8,
    mb: 4,
  },
  alert: {
    mb: 2,
  },
};
