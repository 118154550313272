import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

// TODO: handle pt_BR and non-US locales
// see https://mui.com/x/react-date-pickers/adapters-locale/

export default function setupDateLibrary() {
  return function DateLibraryProvider({ children }) {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {children}
      </LocalizationProvider>
    );
  };
}
