import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone";

export default function ErrorPanel({ errorMessage }) {
  const { t } = useTranslation();

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={styles.container}
    >
      <WarningTwoToneIcon color="disabled" sx={styles.icon} />
      <Typography variant="h6">Error</Typography>
      <Typography variant="body">
        {errorMessage ?? t("Sorry, an unexpected error has occurred.")}
      </Typography>
    </Stack>
  );
}

const styles = {
  container: {
    py: 6,
    px: 4,
  },
  icon: {
    fontSize: 52,
  },
};
