// data/queries/queryFamiliaCristaFiles.js

import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

function fetchFamiliaCristaFiles(index = 0, size = 100) {
  return fetch(`/v1/fc-file?index=${index}&size=${size}`);
}

const getQueryKey = (index = 0, size = 100) => ["fc-files", index, size];

export function useFamiliaCristaFilesQuery({ index = 0, size = 100, ...other }) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(index, size),
    queryFn: () => fetchFamiliaCristaFiles(index, size),
  });
}

export function ensureFamiliaCristaFilesQueryData({ index = 0, size = 100 } = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(index, size),
    queryFn: () => fetchFamiliaCristaFiles(index, size),
  });
}

export function invalidateFamiliaCristaFilesQuery() {
  return queryClient.invalidateQueries(["fc-files"]);
}
