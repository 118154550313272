import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

function fetchCongregations(page = 0, filter = "") {
  return fetch(
    `/v1/congregacao?size=100&index=${page}&q=${encodeURIComponent(filter)}`
  );
}

const getQueryKey = (index = 0, name = "") => ["congregations", index, name];

export function useCongregationsQuery({ page = 0, filter = "", ...other }) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchCongregations(page, filter),
  });
}

export function ensureCongregationsQueryData({ page = 0, filter = "" } = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchCongregations(page, filter),
  });
}

export function invalidateCongregationsQuery() {
  return queryClient.invalidateQueries(["congregations"]);
}
