import { ensurePeopleQuery, usePeopleQuery } from "data/queries/queryPeople";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Avatar from "shared/components/Avatar/Avatar.react";
import IndexPageHeader from "shared/layouts/index/IndexPageHeader.react";
import IndexPageLayout from "shared/layouts/index/IndexPageLayout.react";
import IndexPageTable from "shared/layouts/index/IndexPageTable.react";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

export async function loader() {
  return await ensurePeopleQuery();
}

export default function MembersIndexPage() {
  const { t } = useTranslation();

  return (
    <IndexPageLayout
      useQuery={usePeopleQuery}
      header={
        <IndexPageHeader
          heading={t("Members")}
          routeIndex="/members"
          routeNew="/members/new"
        />
      }
      table={
        <IndexPageTable
          headers={[
            t("Name"),
            t("Congregation"),
            t("Role"),
            t("Email"),
            t("Phone"),
          ]}
          renderRow={({ id, name, email, phone, congregacao, tipo }) => (
            <TableRow hover key={id} tabIndex={-1}>
              <TableCell>
                <Link
                  component={RouterLink}
                  to={`/members/${id}`}
                  underline="hover"
                >
                  <Stack spacing={2} direction="row" alignItems="center">
                    <Avatar
                      name={name}
                      photo={
                        undefined /*TODO https://trello.com/c/6ReAMNeA add member photo */
                      }
                    />
                    <Typography noWrap>{name}</Typography>
                  </Stack>
                </Link>
              </TableCell>
              <TextCell>{congregacao.name}</TextCell>
              <TextCell>{t(tipo)}</TextCell>
              <TextCell>{email}</TextCell>
              <TextCell>{phone}</TextCell>
              <TableCell key="actions"></TableCell>
            </TableRow>
          )}
        />
      }
    />
  );
}

function TextCell({ children }) {
  return (
    <TableCell>
      <Typography variant="subtitle2" noWrap>
        {children}
      </Typography>
    </TableCell>
  );
}
