import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const DEPARTMENTS = [
  "ADOLESCENTES",
  "CAVALHEIROS",
  "CORAL",
  "DIACONOS",
  "GRUPO_DE_LOUVOR",
  "JOVENS_CASAIS",
  "JOVENS",
  "JUNIORES",
  "MULHERES_DE_FE",
  "OBREIROS",
  "ORQUESTRA",
];

export default function DepartmentSelect({
  required,
  label: labelProp,
  ...props
}) {
  const { t } = useTranslation();
  const data = useMemo(
    () =>
      DEPARTMENTS.map((v) => [v, t(v)]).sort((tupleA, tupleB) =>
        tupleA[1].localeCompare(tupleB[1])
      ),
    [t]
  );

  const label = (labelProp ?? t("Department")) + (required ? " *" : "");

  return (
    <FormControl>
      <InputLabel>{label}</InputLabel>
      <Select {...props} label={label} required={required}>
        {data.map(([id, label]) => (
          <MenuItem key={id} value={id}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}