import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

function fetchCaringGroupsReports(page = 0, filter = "") {
  return fetch(
    `/v1/caring-groups-relatorio?size=100&index=${page}&name=${encodeURIComponent(
      filter
    )}`
  );
}
const getQueryKey = (index = 0, name = "") => [
  "caring-groups-relatorio",
  index,
  name,
];

export function useCaringGroupsReportsQuery({
  page = 0,
  filter = "",
  ...other
}) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchCaringGroupsReports(page, filter),
  });
}

export function ensureCaringGroupsReportsQueryData({
  page = 0,
  filter = "",
} = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchCaringGroupsReports(page, filter),
  });
}

export function invalidateCaringGroupsReportsQuery() {
  return queryClient.invalidateQueries(["caring-groups-relatorio"]);
}
