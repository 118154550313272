import { useLoggedUserData } from "data/queries/queryLoggedUser";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";

export default function ProfileEditPage() {
  const user = useLoggedUserData();
  const { t } = useTranslation();

  if (!user) {
    return null;
  }

  return (
    <Container>
      <Breadcrumbs heading={t("My Account")} sx={{ mb: { xs: 3, md: 5 } }} />
      <Card>
        <Stack spacing={3} sx={{ p: 3 }}>
          <TextField
            label={t("Congregation")}
            value={user.congregacao.name}
            disabled={true}
          />
          <TextField label={t("Name")} value={user.name} disabled={true} />
          <TextField label={t("Email")} value={user.email} disabled={true} />
          <TextField label={t("Phone")} value={user.phone} disabled={true} />
        </Stack>
      </Card>
    </Container>
  );
}
