import Toolbar from "@mui/material/Toolbar";
import SearchInput from "shared/components/SearchInput/SearchInput.react";

export default function IndexPageToolbar({ onSearch }) {
  return onSearch == null ? null : (
    <Toolbar
      sx={[
        {
          height: 96,
          display: "flex",
          justifyContent: "space-between",
          p: "0, 1, 0, 3",
        },
      ]}
    >
      <SearchInput onSearch={onSearch} />
    </Toolbar>
  );
}
