import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import Button from "@mui/material/Button";

export default function DetailsPageHeader({
  heading,
  label,
  routeIndex,
  routeUpdate,
}) {
  const { t } = useTranslation();

  return (
    <Breadcrumbs
      heading={heading}
      links={[
        {
          name: heading,
          to: routeIndex,
        },
        { name: label },
      ]}
      action={
        routeUpdate != null && (
          <Button
            component={RouterLink}
            to={routeUpdate}
            variant="contained"
            startIcon={<EditIcon />}
          >
            {t("Edit")}
          </Button>
        )
      }
      sx={{ mb: { xs: 3, md: 5 } }}
    />
  );
}
