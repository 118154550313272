import { styled, alpha } from "@mui/material/styles";
import { useCallback, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import SearchIcon from "@mui/icons-material/Search";

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 280,
  transition: theme.transitions.create(["box-shadow"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
    delay: 100,
  }),
  "&.Mui-focused": {
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

export default function SearchInput({ onSearch }) {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const onBlur = useCallback((e) => setValue((v) => v.trim()), []);
  const onSearchDebounced = useDebouncedCallback(onSearch, 250);
  const inputRef = useRef(null);

  const onInput = useCallback(
    (e) => {
      const newValue = e.target.value;
      setValue(newValue);
      onSearchDebounced(newValue);
    },
    [onSearchDebounced]
  );

  const onClear = useCallback(() => {
    setValue("");
    onSearch("");
    inputRef?.current?.focus();
  }, [onSearch]);

  const onKeyUp = useCallback(
    (e) => {
      if (e.charCode === 13 || e.key === "Enter") {
        onSearch(e.target.value);
      } else if (e.charCode === 27 || e.key === "Escape") {
        onClear();
      }
    },
    [onSearch, onClear]
  );

  return (
    <StyledSearch
      onBlur={onBlur}
      onInput={onInput}
      onKeyUp={onKeyUp}
      value={value}
      placeholder={t("Search")}
      inputRef={inputRef}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon sx={styles.icon} />
        </InputAdornment>
      }
      endAdornment={
        value !== "" && (
          <InputAdornment position="end">
            <IconButton
              aria-label={t("Clear search")}
              onClick={onClear}
              edge="end"
            >
              <ClearIcon sx={styles.icon} />
            </IconButton>
          </InputAdornment>
        )
      }
    />
  );
}

const styles = {
  icon: { color: "text.disabled", width: 20, height: 20 },
};
