import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useGuestsQuery,
  ensureGuestsQueryData,
} from "data/queries/queryGuests";
import IndexPageHeader from "shared/layouts/index/IndexPageHeader.react";
import IndexPageLayout from "shared/layouts/index/IndexPageLayout.react";
import IndexPageTable from "shared/layouts/index/IndexPageTable.react";
import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

export async function loader() {
  return await ensureGuestsQueryData();
}

export default function GuestsIndexPage() {
  const { t } = useTranslation();

  return (
    <IndexPageLayout
      useQuery={useGuestsQuery}
      header={
        <IndexPageHeader heading={t("Guests")} routeIndex="/guests/list" />
      }
      table={
        <IndexPageTable
          headers={[
            t("Name"),
            t("Congregation"),
            t("Visitation Date"),
            t("Email"),
            t("Phone"),
            t("Preferred contact method"),
          ]}
          renderRow={({
            id,
            pessoa,
            congregacao,
            data_visita,
            prefContact,
          }) => (
            <TableRow hover key={id} tabIndex={-1}>
              <TableCell>{pessoa.name}</TableCell>
              <TextCell>{congregacao.name}</TextCell>
              <TextCell>{data_visita}</TextCell>
              <TextCell>{pessoa.email}</TextCell>
              <TextCell>{pessoa.phone}</TextCell>
              <TextCell>{prefContact ?? pessoa.prefContact}</TextCell>
              <TableCell key="actions"></TableCell>
            </TableRow>
          )}
        />
      }
    />
  );
}

function TextCell({ children }) {
  return (
    <TableCell>
      <Typography variant="subtitle2" noWrap>
        {children}
      </Typography>
    </TableCell>
  );
}
