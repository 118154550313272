import fetch from "data/fetch";
import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";

function fetchFamiliaCrista(id) {
  return fetch(`/v1/familia-crista/${id}`);
}

const getQueryKey = (id) => ["familia-crista", id];

export function useFamiliaCristaQuery({ id, ...other }) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(id),
    queryFn: () => fetchFamiliaCrista(id),
  });
}

export function ensureFamiliaCristaQueryData(id) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(id),
    queryFn: () => fetchFamiliaCrista(id),
  });
}

export function invalidateFamiliaCristaQuery() {
  return queryClient.invalidateQueries(["familia-crista"]);
}
