import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

// Fetch all system permissions
function fetchSystemPermissions() {
  return fetch("/v1/system-permission");
}

const getSystemPermissionsKey = () => ["system-permissions"];

export function useSystemPermissionsQuery(options = {}) {
  return useQuery({
    queryKey: getSystemPermissionsKey(),
    queryFn: fetchSystemPermissions,
    ...options,
  });
}

export function ensureSystemPermissionsData() {
  return queryClient.ensureQueryData({
    queryKey: getSystemPermissionsKey(),
    queryFn: fetchSystemPermissions,
  });
}
