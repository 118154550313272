import { useLoggedUserPermissions } from "data/queries/queryLoggedUser";
import { useCallback } from "react";

export default function usePermissions() {
  const permissions = useLoggedUserPermissions();

  return useCallback(
    (actionType, moduleId) => hasPermission(permissions, actionType, moduleId),
    [permissions]
  );
}

/**
 * Permissions follow the pattern:
 *
 *    action : scope
 *
 * For example:
 *
 *    "read:congregacao"
 *    "create:congregacao"
 *
 * The actions usually follow CRUD:
 *
 *    create, read, update, delete
 *
 * Edge case number 1: we can use asterisk * to give access to "all" actions
 *
 *    "*:congregacao" means you can do all actions in congregacao
 *
 * Edge case number 2: actions can be scoped to "local", "regional", and
 * "global".
 *
 *    "read:congregacao",
 *    "read-local:congregacao",
 *    "read-regional:congregacao",
 *    "read-global:congregacao"
 *
 * Most of these nuances are only necessary for server-side APIs. As far as the
 * client-side is concerned, we just need to know if the user can "read any
 * data in congregacao" in order to include a sidebar navigation item.
 *
 * We usually don't need to dig deeper into local vs regional permissions.
 */
function hasPermission(permissions, actionType, moduleId) {
  const actionTypeLocal = `${actionType}-local`;
  const actionTypeRegional = `${actionType}-regional`;
  const actionTypeGlobal = `${actionType}-global`;

  return (
    hasPermissionExact(permissions, actionType, moduleId) ||
    hasPermissionExact(permissions, actionTypeLocal, moduleId) ||
    hasPermissionExact(permissions, actionTypeRegional, moduleId) ||
    hasPermissionExact(permissions, actionTypeGlobal, moduleId)
  );
}

function hasPermissionExact(permissions, actionType, moduleId) {
  return (
    // global permissions ( "read:*" or "*:caring-groups")
    permissions.has(`${actionType}:*`) ||
    permissions.has(`*:${moduleId}`) ||
    // exact permission
    permissions.has(`${actionType}:${moduleId}`)
  );
}
