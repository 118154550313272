import Box from "@mui/material/Box";
import adbelem_logo from "./adbelem_logo.png";
import adbelem_logomark from "./adbelem_logomark.png";
import adbelem_logo_2x from "./adbelem_logo@2x.png";
import adbelem_logomark_2x from "./adbelem_logomark@2x.png";

function LogoWordmark({ logomark, ...props }) {
  return (
    <Box
      as="img"
      {...props}
      src={logomark ? adbelem_logomark : adbelem_logo}
      srcSet={(logomark ? adbelem_logomark_2x : adbelem_logo_2x) + " 2x"}
    />
  );
}

export default LogoWordmark;
