import React, { useState, useEffect } from "react";
import { FormControl, TextField, Checkbox } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useTranslation } from "react-i18next";
import fetch from "data/fetch";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function RoleSelect({ value = [], onChange, required, label: labelProp }) {
  const { t } = useTranslation();
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    async function fetchRoles() {
      try {
        const data = await fetch("/v1/system-role");
        const filteredRoles = data.data
          .filter(role => role.show)
          .map(role => ({ id: role.role, label: t(role.role) }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setRoles(filteredRoles);
      } catch (error) {
        console.error("Failed to fetch roles:", error);
      }
    }
    fetchRoles();
  }, [t]);

  const label = (labelProp ?? t("Permissions")) + (required ? " *" : "");

  return (
    <FormControl fullWidth>
      <Autocomplete
        multiple
        id="roles-autocomplete"
        options={roles}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        value={value.map(val => roles.find(role => role.id === val) || { id: val, label: t(val) })}
        onChange={(event, newValue) => {
          onChange(newValue.map(role => role.id));
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} label={label} />
        )}
      />
    </FormControl>
  );
}
