import React from 'react';
import ReactDOM from "react-dom/client";
import App from "App";
import * as Sentry from "@sentry/react";
import config from 'config/config';
import {
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
  } from 'react-router-dom';

Sentry.init({
    dsn: "https://dc702ae32b5ff1498c1518fabb07d1c0@o4506140332195840.ingest.us.sentry.io/4507307015405568",
    integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
      }),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      })
    ],

    release: config.version,
    environment: config.env,
    enabled: config.env !== 'local',
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
  
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
 });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
