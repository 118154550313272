import fetch from "data/fetch";
import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";

function fetchCaringGroup(id) {
  return fetch(`/v1/caring-groups/${id}`);
}

const getQueryKey = (id) => ["caring-groups", id];

export function useCaringGroupQuery({ id, ...other }) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(id),
    queryFn: () => fetchCaringGroup(id),
    enabled: id != null,
  });
}

export function ensureCaringGroupQueryData(id) {
  return id == null
    ? null
    : queryClient.ensureQueryData({
        queryKey: getQueryKey(id),
        queryFn: () => fetchCaringGroup(id),
      });
}

export function invalidateCaringGroupQuery() {
  return queryClient.invalidateQueries(["caring-groups"]);
}
