import fetch from "data/fetch";
import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";

function fetchCaringGroupsReport(id) {
  return fetch(`/v1/caring-groups-relatorio/${id}`);
}

const getQueryKey = (id) => ["caring-groups-report", id];

export function useCaringGroupsReportQuery({ id, ...other }) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(id),
    queryFn: () => fetchCaringGroupsReport(id),
  });
}

export function ensureCaringGroupsReportQueryData(id) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(id),
    queryFn: () => fetchCaringGroupsReport(id),
  });
}

export function invalidateCaringGroupsReportQuery(id) {
  return queryClient.invalidateQueries(["caring-groups-report", id]);
}
