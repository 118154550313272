import * as React from "react";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";

import {
  deepOrange,
  blue,
  green,
  red,
  pink,
  cyan,
  teal,
  lime,
  amber,
  indigo,
  yellow,
  brown,
  grey,
  purple,
  lightBlue,
  lightGreen,
  deepPurple,
} from "@mui/material/colors";

// Array of Material UI color objects
const colors = [
  blue,
  green,
  red,
  pink,
  cyan,
  teal,
  lime,
  amber,
  indigo,
  yellow,
  brown,
  grey,
  purple,
  lightBlue,
  lightGreen,
  deepOrange,
  deepPurple,
];

const shades = [400, 500, 600, 700, 800];

// Function to hash a string into a numeric value
function hashStringToNumber(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash);
}

// Function to map a name to a color
function nameToColor(fullName) {
  const hash = hashStringToNumber(fullName);
  const colorIndex = hash % colors.length;
  const shadeIndex = hash % shades.length;
  const shadeKey = shades[shadeIndex];

  return colors[colorIndex][shadeKey];
}

function getInitials(name) {
  const parts = name.split(" ");

  if (parts.length === 1) {
    return parts[0][0];
  }

  return parts[0][0] + parts[1][0];
}

export default function LetterAvatars({ name, photo }) {
  if (photo != null) {
    return <Avatar alt={name} src={photo} />;
  }

  if (name == null || name.length === 0) {
    return (
      <Avatar>
        <PersonIcon />
      </Avatar>
    );
  }

  return (
    <Avatar
      sx={{ bgcolor: nameToColor(name) }}
      style={{ textDecoration: "none" }}
    >
      {getInitials(name)}
    </Avatar>
  );
}
