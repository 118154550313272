import { EmailValidator } from "commons-validator-js";

const validator = new EmailValidator();

export const isEmailValid = (email) => {
  if (!email) {
    return false;
  }

  return validator.isValid(email);
};
