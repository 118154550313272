import CssBaseline from "@mui/material/CssBaseline";
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";
import ThemeGlobalStyles from "./ThemeGlobalStyles.react";
import { alpha } from "@mui/material/styles";

const transparentShadown = alpha("#919EAB", 0.16);

const theme = createTheme({
  typography: {
    fontFamily: "Public Sans, sans-serif",
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    h1: {
      fontWeight: 800,
      lineHeight: 1.25,
      fontSize: "2.5rem",
      "@media (min-width:600px)": {
        fontSize: "3.25rem",
      },
      "@media (min-width:900px)": {
        fontSize: "3.625rem",
      },
      "@media (min-width:1200px)": {
        fontSize: "4rem",
      },
      fontFamily: "Public Sans, sans-serif",
    },
    h2: {
      fontWeight: 800,
      lineHeight: 1.3333333333333333,
      fontSize: "2rem",
      "@media (min-width:600px)": {
        fontSize: "2.5rem",
      },
      "@media (min-width:900px)": {
        fontSize: "2.75rem",
      },
      "@media (min-width:1200px)": {
        fontSize: "3rem",
      },
      fontFamily: "Public Sans, sans-serif",
    },
    h3: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: "1.5rem",
      "@media (min-width:600px)": {
        fontSize: "1.625rem",
      },
      "@media (min-width:900px)": {
        fontSize: "1.875rem",
      },
      "@media (min-width:1200px)": {
        fontSize: "2rem",
      },
      fontFamily: "Public Sans, sans-serif",
    },
    h4: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: "1.25rem",
      "@media (min-width:600px)": {
        fontSize: "1.25rem",
      },
      "@media (min-width:900px)": {
        fontSize: "1.5rem",
      },
      "@media (min-width:1200px)": {
        fontSize: "1.5rem",
      },
      fontFamily: "Public Sans, sans-serif",
    },
    h5: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: "1.125rem",
      "@media (min-width:600px)": {
        fontSize: "1.1875rem",
      },
      "@media (min-width:900px)": {
        fontSize: "1.25rem",
      },
      "@media (min-width:1200px)": {
        fontSize: "1.25rem",
      },
      fontFamily: "Public Sans, sans-serif",
    },
    h6: {
      fontWeight: 700,
      lineHeight: 1.5555555555555556,
      fontSize: "1.0625rem",
      "@media (min-width:600px)": {
        fontSize: "1.125rem",
      },
      "@media (min-width:900px)": {
        fontSize: "1.125rem",
      },
      "@media (min-width:1200px)": {
        fontSize: "1.125rem",
      },
      fontFamily: "Public Sans, sans-serif",
    },
    subtitle1: {
      fontWeight: 600,
      lineHeight: 1.5,
      fontSize: "1rem",
      fontFamily: "Public Sans, sans-serif",
    },
    subtitle2: {
      fontWeight: 600,
      lineHeight: 1.5714285714285714,
      fontSize: "0.875rem",
      fontFamily: "Public Sans, sans-serif",
    },
    body1: {
      lineHeight: 1.5,
      fontSize: "1rem",
      fontFamily: "Public Sans, sans-serif",
      fontWeight: 400,
    },
    body2: {
      lineHeight: 1.5714285714285714,
      fontSize: "0.875rem",
      fontFamily: "Public Sans, sans-serif",
      fontWeight: 400,
    },
    caption: {
      lineHeight: 1.5,
      fontSize: "0.75rem",
      fontFamily: "Public Sans, sans-serif",
      fontWeight: 400,
    },
    overline: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: "0.75rem",
      textTransform: "uppercase",
      fontFamily: "Public Sans, sans-serif",
    },
    button: {
      fontWeight: 700,
      lineHeight: 1.7142857142857142,
      fontSize: "0.875rem",
      textTransform: "unset",
      fontFamily: "Public Sans, sans-serif",
    },
    htmlFontSize: 16,
    fontSize: 14,
    fontWeightLight: 300,
    inherit: {
      fontFamily: "inherit",
      fontWeight: "inherit",
      fontSize: "inherit",
      lineHeight: "inherit",
      letterSpacing: "inherit",
    },
  },
  customShadows: {
    z1: `0 1px 2px 0 ${transparentShadown}`,
    z4: `0 4px 8px 0 ${transparentShadown}`,
    z8: `0 8px 16px 0 ${transparentShadown}`,
    z12: `0 12px 24px -4px ${transparentShadown}`,
    z16: `0 16px 32px -4px ${transparentShadown}`,
    z20: `0 20px 40px -4px ${transparentShadown}`,
    z24: `0 24px 48px 0 ${transparentShadown}`,
    card: `rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px`,
  },
});

theme.components = {
  ...theme.components,
  MuiCard: {
    styleOverrides: {
      root: {
        position: "relative",
        boxShadow: theme.customShadows.card,
        borderRadius: theme.shape.borderRadius * 2,
        zIndex: 0, // Fix Safari overflow: hidden with border radius
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        padding: theme.spacing(3, 3, 0),
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: theme.spacing(3),
      },
    },
  },
};

export default function ThemeProvider({ children }) {
  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <ThemeGlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
