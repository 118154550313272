import { useNavigate, useParams } from "react-router-dom";
import Modal from "@mui/material/Modal";
import {
  ensureCaringGroupsReportQueryData,
  useCaringGroupsReportQuery,
} from "data/queries/queryCaringGroupsReport";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useCallback } from "react";
import { useCaringGroupReportDeleteMutation } from "data/mutations/mutateCaringGroupReport";
import Alert from "@mui/material/Alert";
import DeleteIcon from "@mui/icons-material/Delete";

export async function loader({ params: { id } }) {
  return await ensureCaringGroupsReportQueryData(id);
}

export default function CaringGroupsReportsViewPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: queryData } = useCaringGroupsReportQuery({ id });
  const caringGroupReport = queryData?.data;
  const handleClose = () => navigate(-1);
  const deleteMutation = useCaringGroupReportDeleteMutation({
    onSuccess() {
      navigate(-1);
    },
  });
  const onDeleteClick = useCallback(async () => {
    if (!window.confirm(t("Do you confirm the deletion?"))) {
      return false;
    }

    deleteMutation.mutate({ id });
  }, [t, id, deleteMutation]);

  if (caringGroupReport == null) {
    return null;
  }

  const { dataFrequencia, caringGroup, relatorioRelations } = caringGroupReport;

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <CardHeader title={"Caring Group Report"} />
        <CardContent as={Stack} spacing={3}>
          {deleteMutation.error && (
            <Alert severity="error">{t(deleteMutation.error)}</Alert>
          )}
          <dl>
            <dt>{t("Congregation")}</dt>
            <dd>{caringGroup.congregacao.name}</dd>

            <dt>{t("Leader")}</dt>
            <dd>{caringGroup.lider.name}</dd>

            <dt>{t("Department")}</dt>
            <dd>{caringGroup.departamento}</dd>

            <dt>{t("Date")}</dt>
            <dd>{dataFrequencia}</dd>
          </dl>
          {relatorioRelations.map((relation) => (
            <dl key={relation.id}>
              <dt>{t("Member")}</dt>
              <dd>{relation.caringGroupRelation.pessoa.name}</dd>

              <dt>{t("Status")}</dt>
              <dd>{relation.situacao}</dd>

              <dt>{t("Comment")}</dt>
              <dd>{relation.comentario ?? "-"}</dd>
            </dl>
          ))}
        </CardContent>
        <CardActions>
          <Button
            startIcon={<DeleteIcon />}
            variant="outlined"
            color="error"
            size="large"
            disabled={deleteMutation.isLoading}
            onClick={onDeleteClick}
          >
            {deleteMutation.isLoading ? t("Deleting...") : t("Delete")}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};
