import { useId, useState } from "react";
import { useTranslation } from "react-i18next";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";

const LOCALES = {
  pt_BR: {
    label: "Português",
    country: "br",
  },
  en: {
    label: "English",
    country: "us",
  },
  es: {
    label: "Español",
    country: "es",
  },
};

export default function ViewportLanguageSelector() {
  const { i18n } = useTranslation();
  const localeSelected = i18n.language;
  const { country, label } = LOCALES[localeSelected];
  const btnID = useId();
  const menuID = useId();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const onSelectedLocale = (locale) => {
    i18n.changeLanguage(locale);
    handleClose();
  };

  return [
    <IconButton
      id={btnID}
      key="lang-button"
      aria-controls={open ? menuID : undefined}
      aria-haspopup="true"
      aria-expanded={open ? "true" : undefined}
      onClick={handleClick}
      sx={styles.root}
    >
      <img
        alt={label}
        loading="lazy"
        width="24"
        height="auto"
        src={`https://flagcdn.com/w40/${country}.png`}
        srcSet={`https://flagcdn.com/w80/${country}.png 2x`}
      />
    </IconButton>,
    <Menu
      id={menuID}
      key="lang-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": btnID,
      }}
    >
      {Object.keys(LOCALES).map((locale) => {
        const { country, label } = LOCALES[locale];
        return (
          <MenuItem
            key={locale}
            onClick={() => onSelectedLocale(locale)}
            selected={locale === localeSelected}
          >
            <ListItemIcon>
              <img
                alt={label}
                loading="lazy"
                width="20"
                height="auto"
                src={`https://flagcdn.com/w20/${country}.png`}
                srcSet={`https://flagcdn.com/w40/${country}.png 2x`}
              />
            </ListItemIcon>
            <ListItemText>{label}</ListItemText>
          </MenuItem>
        );
      })}
    </Menu>,
  ];
}

const styles = {
  root: {
    width: 40,
    height: 40,
  },
};
