import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

function fetchGuests(page = 0, filter = "") {
  return fetch(
    `/v1/convidado-visita?size=100&index=${page}&q=${encodeURIComponent(
      filter
    )}`
  );
}
const getQueryKey = (index = 0, name = "") => ["guests", index, name];

export function useGuestsQuery({ page = 0, filter = "", ...other }) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchGuests(page, filter),
  });
}

export function ensureGuestsQueryData({ page = 0, filter = "" } = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchGuests(page, filter),
  });
}

export function invalidateGuestsQuery() {
  return queryClient.invalidateQueries(["guests"]);
}
