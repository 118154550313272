import { ensureCongregationQueryData } from "data/queries/queryCongregations";
import FamiliaCristaForm from "./components/FamiliaCristaForm.react";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { invalidateFamiliaCristaQuery } from "data/queries/queryFamiliaCrista";
import fetch from "data/fetch";


export async function loader() {
  // preload congregations
  await ensureCongregationQueryData();
  return null;
}

export async function action() {
  // TODO move form submit to action
}

export default function FamiliaCristaNewPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // TODO move to mutations
  const onSubmit = async ({ congregationId, leaderId, department, selectedMembers }) => {
    const promises = [];

    promises.push(
      await fetch(`/v1/familia-crista/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          liderId: leaderId,
          congregacaoId: congregationId,
          departamento: department,
          membros: selectedMembers.map((member) => member.memberId)
        }),
      })
    );

    if (promises.length) {
      await Promise.all(promises);
    }

    invalidateFamiliaCristaQuery();
    navigate("/familia-crista/list");
  };

  return (
    <FamiliaCristaForm
      onSubmit={onSubmit}
      breadcrumbs={
        <Breadcrumbs
          heading={t("Create a new Christian Family Group")}
          links={[
            {
              name: t("Christian Family"),
              to: "/familia-crista/list",
            },
            { name: t("New") },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
}
