import {
  ensureCaringGroupReportsQueryData,
  useCaringGroupReportsQuery,
} from "data/queries/queryCaringGroupReports";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import {
  ensureCaringGroupMembersQueryData,
  useCaringGroupMembersQuery,
} from "data/queries/queryCaringGroupMembers";
import {
  ensureCaringGroupQueryData,
  useCaringGroupQuery,
} from "data/queries/queryCaringGroup";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import DetailsPageHeader from "shared/layouts/details/DetailsPageHeader.react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import AddIcon from "@mui/icons-material/Add";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export async function loader({ params: { id } }) {
  return await Promise.all([
    ensureCaringGroupQueryData(id),
    ensureCaringGroupMembersQueryData(id),
    ensureCaringGroupReportsQueryData({ id }),
  ]);
}

export default function CaringGroupsViewPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: queryData } = useCaringGroupQuery({ id });
  const caringGroup = queryData?.data;

  return (
    <Container>
      <DetailsPageHeader
        heading={t("Caring Groups")}
        label={caringGroup?.lider?.name ?? "..."}
        routeIndex="/caring_groups"
        routeUpdate={`/caring_groups/${id}/update`}
      />

      {caringGroup && (
        <Stack spacing={4}>
          <FormCard title="Detalhes">
            <dl>
              <dt>{t("Congregation")}</dt>
              <dd>{caringGroup.congregacao.name}</dd>

              <dt>{t("Leader")}</dt>
              <dd>{caringGroup.lider.name}</dd>

              <dt>{t("Department")}</dt>
              <dd>{t(caringGroup.departamento)}</dd>
            </dl>
          </FormCard>
          <MembersCard id={id} />
          <ReportTable id={id} />
        </Stack>
      )}
    </Container>
  );
}

function FormCard({ action, title, children }) {
  return (
    <Card>
      <CardHeader title={title} action={action} />
      <CardContent as={Stack} spacing={3}>
        {children}
      </CardContent>
    </Card>
  );
}

function MembersCard({ id }) {
  const { data: queryData } = useCaringGroupMembersQuery({ id });
  const members = queryData?.data;

  return (
    <FormCard title="Membros">
      {members?.map((m) => (
        <Typography noWrap key={m.id}>
          {m.name}
        </Typography>
      ))}
    </FormCard>
  );
}

function ReportTable({ id }) {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const { data: queryData, ...queryState } = useCaringGroupReportsQuery({
    id,
    page,
    from,
    to,
    keepPreviousData: true,
  });
  const onPageChange = useCallback((_e, page) => {
    setPage(page);
  }, []);

  return (
    <Card>
      <CardHeader
        title={t("Reports")}
        action={
          <Button
            component={RouterLink}
            to={`reports/new`}
            variant="outlined"
            startIcon={<AddIcon />}
          >
            {t("New Report")}
          </Button>
        }
      />

      <Toolbar>
        <Stack spacing={2} direction="row" sx={{ mt: 2 }}>
          <DatePicker
            required={true}
            label={t("From")}
            value={from}
            onChange={setFrom}
          />
          <DatePicker
            required={true}
            label={t("To")}
            value={to}
            onChange={setTo}
          />
        </Stack>
      </Toolbar>
      <CardContent as={Stack} spacing={3}>
        {queryData?.data?.flatMap((report) => 
          report.relatorioRelations.map((relation) => (
            <Stack direction="row" spacing={2} key={relation.id}>
              <Typography>{report.dataFrequencia}</Typography>
              <Typography>{relation.caringGroupRelation.pessoa.name}</Typography>
              <Typography>{relation.situacao}</Typography>
              <Typography>{relation.comentario}</Typography>
            </Stack>
          ))
        )}
      </CardContent>
    </Card>
  );
}
