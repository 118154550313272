import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";
import queryClient from "data/queryClient";

function fetchPeople(page = 0, filter = "") {
  return fetch(
    `/v1/pessoa?size=100&index=${page}&q=${encodeURIComponent(filter)}`
  );
}

const getQueryKey = (page = 0, name = "") => ["people", page, name];

export function usePeopleQuery({ page = 0, filter = "", ...other }) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchPeople(page, filter),
  });
}

export function ensurePeopleQuery({ page = 0, filter = "" } = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchPeople(page, filter),
  });
}

export function invalidatePeopleQuery() {
  return queryClient.invalidateQueries(["people"]);
}
