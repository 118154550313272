import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import * as Auth from "shared/auth/Auth";
import fetch from "data/fetch";
import queryClient from "data/queryClient";

function fetchLoggedUser() {
  return fetch("/v1/profile", {
    method: "GET",
  });
}

const queryKey = ["logged-user"];
const queryFn = fetchLoggedUser;

export function useLoggedUserData() {
  const { data } = useLoggedUserQuery();
  return data;
}

export function useLoggedUserPermissions() {
  const user = useLoggedUserData();
  return useMemo(() => new Set(user?.permissions ?? []), [user]);
}

export function useLoggedUserQuery() {
  const isAuthInitialized = Auth._throwIfUnitialized();

  return useQuery({
    queryKey,
    queryFn,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: isAuthInitialized && Auth.getUser() != null,
  });
}


export function ensureLoggedUserQueryData() {
  return queryClient.ensureQueryData(queryKey, queryFn);
}

export function setLoggedUserQueryData(data) {
  return queryClient.setQueryData(queryKey, data);
}

export function invalidateLoggedUserQuery() {
  return queryClient.invalidateQueries(queryKey);
}
