import { useCallback, useState } from "react";

export default function useAutoCompleteState() {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const onChange = useCallback((_e, v) => {
    setValue(v);
  }, []);

  const onInputChange = useCallback((_e, v) => {
    setInputValue(v);
  }, []);

  return [value, inputValue, onChange, onInputChange];
}
