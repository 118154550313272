import { useEffect } from 'react';
import { useRouteError } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { captureException } from '@sentry/react';

export default function RootGlobalError() {
  const { t } = useTranslation();
  const error = useRouteError();
  const knownErrorMessage = useKnownErrorMessage(error);

  useEffect(() => {
    console.error(error);
    if (error.status === 404) {
      console.log('404: Page not found');
    } else {
      captureException(error, { level: 'fatal' });
    }
    
  }, [error]);

  return (
    <Stack
      spacing={2}
      justifyContent="center"
      alignItems="center"
      sx={styles.root}
    >
      <Typography variant="h1">{t("Oops!")}</Typography>
      {knownErrorMessage ? (
        <Typography variant="body1">{knownErrorMessage}</Typography>
      ) : (
        <>
          <Typography variant="subtitle1">
            {t("Sorry, an unexpected error has occurred.")}
          </Typography>
        </>
      )}
    </Stack>
  );
}

const styles = {
  root: {
    height: "100%",
  },
};

function useKnownErrorMessage(error) {
  const { t } = useTranslation();

  switch (error.status) {
    case 404:
      return t("Page not found.");
    default:
      return null;
  }
}
