import { QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import { StrictMode } from "react";
import setupDateLibrary from "setupDateLibrary";
import setupQueryClient from "setupQueryClient";
import setupRoutes from "setupRoutes";
import setupTheme from "setupTheme";
import setupTranslation from "setupTranslation";

setupTranslation();

const queryClient = setupQueryClient();
const DateLibraryProvider = setupDateLibrary();
const ThemeProvider = setupTheme();
const router = setupRoutes();

export default function App() {
  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <DateLibraryProvider>
          <ThemeProvider>
            <RouterProvider router={router} />
          </ThemeProvider>
        </DateLibraryProvider>
      </QueryClientProvider>
    </StrictMode>
  );
}
