import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ListSubheader from "@mui/material/ListSubheader";
import Stack from "@mui/material/Stack";

import { useTranslation } from "react-i18next";
import { useEffect, useId, useMemo, useState } from "react";
import { usePeopleQuery } from "data/queries/queryPeople";

function PeopleSelect({ onChange, required, label }) {
  const { t } = useTranslation();
  const data = useData();
  const id = useComponentId();
  const regionalMap = useRegionalMap(data);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    onChange && onChange(value);
  }, [onChange, value]);

  return (
    <Autocomplete
      required={required}
      value={value}
      inputValue={inputValue}
      id={id}
      options={data}
      autoHighlight={true}
      groupBy={(option) => getGroupHeader(option)}
      getOptionLabel={(option) => option.name}
      onChange={(_event, newValue) => {
        setValue(newValue);
      }}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderOption={(props, option) => (
        <Stack direction="row" alignItems="center" component="li" {...props}>
          <div style={{ flexGrow: 1 }}>{option.name}</div>
        </Stack>
      )}
      slotProps={{
        clearIndicator: {
          style: {
            visibility: "visible",
          },
        },
      }}
      renderInput={(params) => (
        <TextField {...params} label={label ?? t("Person")} />
      )}
      renderGroup={(params) => {
        const regional = regionalMap.get(params.group);
        const country = regional.country.toLowerCase();

        return (
          <li key={params.key}>
            <ListSubheader component="div" disableSticky={true}>
              <Stack direction="row" spacing={1} alignItems="center">
                <img
                  alt="Country flag"
                  loading="lazy"
                  width="20"
                  height="auto"
                  src={`https://flagcdn.com/w20/${country}.png`}
                  srcSet={`https://flagcdn.com/w40/${country}.png 2x`}
                />
                <span>{params.group}</span>
              </Stack>
            </ListSubheader>
            <ul style={{ paddingLeft: 0 }}>{params.children}</ul>
          </li>
        );
      }}
    />
  );
}

function useComponentId() {
  const id = useId();
  return `congregation-select-${id}`;
}

function useData() {
  const collator = new Intl.Collator();
  const { data } = usePeopleQuery();

  return (data ?? []).sort((a, b) => {
    const reg = collator.compare(getGroupHeader(a), getGroupHeader(b));
    return reg === 0 ? collator.compare(a.name, b.name) : reg;
  });
}

function useRegionalMap(data) {
  return useMemo(() => {
    const regionalMap = new Map();
    data.forEach((entry) => {
      const key = getGroupHeader(entry);

      if (!regionalMap.has(key)) {
        regionalMap.set(key, {
          country: entry.congregacao.country,
        });
      }
    });
    return regionalMap;
  }, [data]);
}

function getGroupHeader(record) {
  return record.congregacao.regional + " - " + record.congregacao.name;
}

export default PeopleSelect;
