import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

function fetchFamiliaCrista(page = 0, filter = "") {
  return fetch(
    `/v1/familia-crista?size=100&index=${page}&name=${encodeURIComponent(
      filter
    )}`
  );
}
const getQueryKey = (index = 0, name = "") => ["familia-crista", index, name];

export function useFamiliaCristaQuery({ page = 0, filter = "", ...other }) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchFamiliaCrista(page, filter),
  });
}

export function ensureFamiliaCristaQueryData({ page = 0, filter = "" } = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchFamiliaCrista(page, filter),
  });
}

export function invalidateFamiliaCristaQuery() {
  return queryClient.invalidateQueries(["familia-crista"]);
}
