import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";

import { ensureFamiliaCristaQueryData, useFamiliaCristaQuery } from "data/queries/queryFamiliaCrista";
import { ensureFamiliaCristaMembersQueryData, useFamiliaCristaMembersQuery } from "data/queries/queryFamiliaCristaMembers";
import { ensureFamiliaCristaReportsQueryData, useFamiliaCristaReportsQuery } from "data/queries/queryFamiliaCristaReports";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import DetailsPageHeader from "shared/layouts/details/DetailsPageHeader.react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export async function loader({ params: { id } }) {
  return await Promise.all([
    ensureFamiliaCristaQueryData(id),
    ensureFamiliaCristaMembersQueryData(id),
    ensureFamiliaCristaReportsQueryData({ id }),
  ]);
}

export default function FamiliaCristaViewPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: queryData } = useFamiliaCristaQuery({ id });
  const familiaCrista = queryData?.data;

  return (
    <Container>
      <DetailsPageHeader
        heading={t("Christian Family")}
        label={familiaCrista?.lider?.name ?? "..."}
        routeIndex="/familia-crista/list"
        routeUpdate={`/familia-crista/${id}/update`}
      />

      {familiaCrista && (
        <Stack spacing={4}>
          <FormCard title="Detalhes">
            <dl>
              <dt>{t("Congregation")}</dt>
              <dd>{familiaCrista.congregacao.name}</dd>

              <dt>{t("Leader")}</dt>
              <dd>{familiaCrista.lider.name}</dd>

              <dt>{t("Department")}</dt>
              <dd>{t(familiaCrista.departamento)}</dd>
            </dl>
          </FormCard>
          <MembersCard id={id} />
          <ReportTable id={id} />
        </Stack>
      )}
    </Container>
  );
}

function FormCard({ title, children }) {
  return (
    <Card>
      <CardHeader title={title} />
      <CardContent as={Stack} spacing={3}>
        {children}
      </CardContent>
    </Card>
  );
}

function MembersCard({ id }) {
  const { data: queryData } = useFamiliaCristaMembersQuery({ id });
  const members = queryData?.data;

  return (
    <FormCard title="Membros">
      {members?.map((m) => (
        <Typography noWrap key={m.id}>
          {m.name}
        </Typography>
      ))}
    </FormCard>
  );
}

function ReportTable({ id }) {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const { data: queryData, ...queryState } = useFamiliaCristaReportsQuery({
    id,
    page,
    from,
    to,
    keepPreviousData: true,
  });
  const onPageChange = useCallback((_e, page) => {
    setPage(page);
  }, []);

  return (
    <Card>
      <CardHeader
        title={t("Reports")}
        action={
          <Button
            component={RouterLink}
            to={`reports/new`}
            variant="outlined"
            startIcon={<AddIcon />}
          >
            {t("New Report")}
          </Button>
        }
      />

      <Toolbar>
        <Stack spacing={2} direction="row" sx={{ mt: 2 }}>
          <DatePicker
            required={true}
            label={t("From")}
            value={from}
            onChange={setFrom}
          />
          <DatePicker
            required={true}
            label={t("To")}
            value={to}
            onChange={setTo}
          />
        </Stack>
      </Toolbar>
      <CardContent as={Stack} spacing={3}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Date")}</TableCell>
                <TableCell>{t("Member Name")}</TableCell>
                <TableCell>{t("Status")}</TableCell>
                <TableCell>{t("Comment")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {queryData?.data?.map((report) =>
                report.relatorioRelations.map((relation) => (
                  <TableRow key={relation.id}>
                    <TableCell>{report.dataReuniao}</TableCell>
                    <TableCell>{relation.familiaCristaRelation.pessoa.name}</TableCell>
                    <TableCell>{relation.situacao}</TableCell>
                    <TableCell>{relation.comentario}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
