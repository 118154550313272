import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useCaringGroupsQuery,
  ensureCaringGroupsQueryData,
} from "data/queries/queryCaringGroups";
import IndexPageHeader from "shared/layouts/index/IndexPageHeader.react";
import IndexPageLayout from "shared/layouts/index/IndexPageLayout.react";
import IndexPageTable from "shared/layouts/index/IndexPageTable.react";
import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

export async function loader() {
  return await ensureCaringGroupsQueryData();
}

export default function CaringGroupsIndexPage() {
  const { t } = useTranslation();

  return (
    <IndexPageLayout
      useQuery={useCaringGroupsQuery}
      hasSearch={false}
      header={
        <IndexPageHeader
          heading={t("Caring Groups")}
          routeIndex="/caring_groups"
          routeNew="/caring_groups/new"
        />
      }
      table={
        <IndexPageTable
          headers={[t("Congregation"), t("Leader"), t("Department")]}
          renderRow={({ id, congregacao, lider, departamento }) => (
            <TableRow hover key={id} tabIndex={-1}>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {congregacao.name}
                </Typography>
              </TableCell>
              <TableCell>
                <Link component={RouterLink} to={`/caring_groups/${id}`}>
                  {lider.name}
                </Link>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {t(departamento)}
                </Typography>
              </TableCell>
              <TableCell key="actions"></TableCell>
            </TableRow>
          )}
        />
      }
    />
  );
}
