import { Outlet, ScrollRestoration } from "react-router-dom";
import { useEffect } from "react";
import { useIsDesktop } from "shared/hooks/useResponsive";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import {
  ensureLoggedUserQueryData,
  useLoggedUserData,
} from "data/queries/queryLoggedUser";
import Box from "@mui/material/Box";
import GuestEditPage from "./guest/GuestEditPage.react";
import ViewportAppBar from "shared/layouts/viewport/ViewportAppBar.react";
import ViewportSideNav, {
  DRAWER_WIDTH,
} from "shared/layouts/viewport/ViewportSideNav.react";

const LS_COLLAPSED_KEY = "viewport-sidenav-collapsed";

export async function loader() {
  const user = await ensureLoggedUserQueryData();
  return { user };
}

export default function RootPage() {
  const loggedUser = useLoggedUserData();
  const hasToFinishRegistration = loggedUser?.requiredActions?.some(
    (x) => x === "FINISH_REGISTRATION"
  );

  return hasToFinishRegistration ? (
    <GuestEditPage data={loggedUser} />
  ) : (
    <Viewport />
  );
}

function Viewport() {
  const [isOpen, toggleDrawer] = useDrawerState();
  const isDesktop = useIsDesktop();

  return (
    <Box sx={styles.root}>
      <ScrollRestoration />
      <ViewportAppBar drawerWidth={DRAWER_WIDTH} toggleDrawer={toggleDrawer} />
      <Box sx={styles.columns}>
        <ViewportSideNav
          drawerWidth={DRAWER_WIDTH}
          isOpen={isOpen}
          toggleDrawer={toggleDrawer}
        />
        <Box
          sx={[styles.main, isDesktop && isOpen && styles.mainDesktopOpen]}
          component="main"
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

function useDrawerState() {
  const isDekstop = useIsDesktop();
  const location = useLocation();
  const diskState = localStorage.getItem(LS_COLLAPSED_KEY) == null;
  const [isDesktopOpen, setDesktopOpen] = useState(diskState);
  const [isMobileOpen, setMobileOpen] = useState(false);

  const toggleDesktopDrawer = () => {
    if (isDesktopOpen) {
      localStorage.setItem(LS_COLLAPSED_KEY, "1");
    } else {
      localStorage.removeItem(LS_COLLAPSED_KEY);
    }

    setDesktopOpen(!isDesktopOpen);
  };

  const toggleMobileDrawer = () => {
    setMobileOpen(!isMobileOpen);
  };

  // route changes should collapse sidenav for mobile
  useEffect(() => {
    setMobileOpen(false);
  }, [location]);

  return isDekstop
    ? [isDesktopOpen, toggleDesktopDrawer]
    : [isMobileOpen, toggleMobileDrawer];
}

const styles = {
  root: {
    height: "100%",
  },
  columns: {
    minHeight: "100%",
    display: "flex",
    flexDirection: ["column", null, "row"],
  },
  main: {
    display: "flex",
    flexGrow: 0,
    minHeight: "100%",
    flexDirection: "column",
    padding: "88px 0 8px 0",
    width: "100%",
  },
  mainDesktopOpen: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
  },
};
