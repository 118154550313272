import { createContext, useContext } from "react";
import { keyframes } from "@mui/system";
import ErrorTableRow from "shared/components/ErrorState/ErrorTableRow.react";
import LoadingTableRow from "shared/components/LoadingState/LoadingTableRow.react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

export const IndexPageTableContext = createContext();

export default function IndexPageTable({ headers, renderRow }) {
  const { filter, onPageChange, page, queryData, queryState } = useContext(
    IndexPageTableContext
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((label, i) => {
            const headCell = typeof label === "string" ? { label } : label;

            return (
              <TableCell key={i} align={headCell.alignRight ? "right" : "left"}>
                {headCell.label}
              </TableCell>
            );
          })}
          <TableCell>{/* TODO: row actions */}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={[queryState.isPreviousData && styles.previousData]}>
        <TableRows
          colSpan={headers.length + 1 /* action cell*/}
          filter={filter}
          page={page}
          queryData={queryData}
          queryState={queryState}
          renderRow={renderRow}
        />
      </TableBody>
      {queryData != null && (
        <TableFooter>
          <TableRow>
            <TablePagination
              count={queryData.totalEntities}
              rowsPerPage={queryData.size}
              page={page}
              onPageChange={onPageChange}
              rowsPerPageOptions={[queryData.size]}
              sx={{ border: "none" }}
            />
          </TableRow>
        </TableFooter>
      )}
    </Table>
  );
}

function TableRows({ colSpan, queryData, queryState, renderRow }) {
  if (queryState.isLoading) {
    return <LoadingTableRow rows={14} cells={colSpan} />;
  }

  if (queryState.isError) {
    return <ErrorTableRow errorMessage={queryState.error?.message} />;
  }

  return queryData.data.map(renderRow);
}

const pulseKeyframe = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
`;

const styles = {
  previousData: {
    animation: `${pulseKeyframe} 1.5s ease-in-out 0.5s infinite`,
  },
};
