import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import Button from "@mui/material/Button";

export default function IndexPageHeader({ heading, routeIndex, routeNew }) {
  const { t } = useTranslation();

  return (
    <Breadcrumbs
      heading={heading}
      links={[
        {
          name: heading,
          to: routeIndex,
        },
        { name: t("List") },
      ]}
      action={
        routeNew != null && (
          <Button
            component={RouterLink}
            to={routeNew}
            variant="contained"
            startIcon={<AddIcon />}
          >
            {t("New")}
          </Button>
        )
      }
      sx={{ mb: { xs: 3, md: 5 } }}
    />
  );
}
