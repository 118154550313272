import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

function fetchFamiliaCristaReports(page = 0, filter = "") {
  return fetch(
    `/v1/familia-crista-relatorio?size=100&index=${page}&name=${encodeURIComponent(
      filter
    )}`
  );
}
const getQueryKey = (index = 0, name = "") => [
  "familia-crista-relatorio",
  index,
  name,
];

export function useFamiliaCristaReportsQuery({
  page = 0,
  filter = "",
  ...other
}) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchFamiliaCristaReports(page, filter),
  });
}

export function ensureFamiliaCristaReportsQueryData({
  page = 0,
  filter = "",
} = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchFamiliaCristaReports(page, filter),
  });
}

export function invalidateFamiliaCristaReportsQuery() {
  return queryClient.invalidateQueries(["familia-crista"]);
}
