import React, { useEffect, useState } from "react";
import fetch from "data/fetch";
import Container from "@mui/material/Container";

const METABASE_SITE_URL = "https://app.adbelem.org/metabase";

export default function MetabaseAnalyticsPageLayout({ dashboardId }) {
  const iframeUrl = useIframeUrl(dashboardId);

  return (
    <Container
      disableGutters={true}
      sx={{
        flexGrow: 1,
        display: "flex",
      }}
    >
      <iframe
        title="Metabase Dashboard"
        src={iframeUrl}
        width="100%"
        height="100%"
        allowtransparency={true}
        frameborder="0"
      />
    </Container>
  );
}

function useIframeUrl(dashboardId) {
  const [iframeUrl, setIframeUrl] = useState("");

  useEffect(() => {
    getURLMetabase(dashboardId)
      .then((signedUrl) => {
        setIframeUrl(signedUrl);
      })
      .catch((error) => {
        console.error("Error fetching Metabase signed URL:", error);
      });
  }, [dashboardId]);

  return iframeUrl;
}

function getExpirationTime() {
  const now = new Date();
  const duration = 30 * 60 * 1000; // 30 minutes in milliseconds
  const expTime = new Date(now.getTime() + duration);
  return expTime.toISOString();
}

function getURLMetabase(dashboardId) {
  return fetch(`/v1/metabase-embed/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      resource: {
        dashboard: dashboardId,
      },
      params: {},
      exp: getExpirationTime(),
    }),
  }).then((data) => {
    const token = data.data;
    return `${METABASE_SITE_URL}/embed/dashboard/${token}#bordered=false&titled=false`;
  });
}
