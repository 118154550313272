import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";

const DEFAULT_CLDR = "US";
const phoneUtils = PhoneNumberUtil.getInstance();
const regexPhonePattern = /^\+[1-9]\d{9,14}$/;

const sanitizeValue = (input) => {
  return input.replace(/[^0-9+]|(?<=[0-9+])\+/g, "");
};

export const isPhoneValid = (phone) => {
  // parseAndKeepRawInput & isValidNumber throws an exception when a number is not valid
  // Hence try/catch and validation error set in the catch
  try {
    const sanitizedValue = sanitizeValue(phone);
    const parsedNumber = phoneUtils.parseAndKeepRawInput(sanitizedValue);

    return (
      regexPhonePattern.test(sanitizedValue) &&
      phoneUtils.isValidNumber(parsedNumber)
    );
  } catch (e) {
    return false;
  }
};

export const formatPhone = (phone) => {
  // parseAndKeepRawInput & isValidNumber throws an exception when a number is not valid
  // Hence try/catch and validation error set in the catch
  try {
    const sanitizedValue = sanitizeValue(phone);
    const parsedNumber = phoneUtils.parseAndKeepRawInput(
      sanitizedValue,
      DEFAULT_CLDR
    );
    return phoneUtils.format(parsedNumber, PhoneNumberFormat.E164);
  } catch {
    return phone;
  }
};
