import Container from "@mui/material/Container";
import IndexPageCard from "./IndexPageCard.react";

export default function IndexPageLayout({
  hasSearch,
  header,
  table,
  useQuery,
  queryParams = {},
}) {
  return (
    <Container>
      {header}
      <IndexPageCard
        hasSearch={hasSearch}
        header={header}
        queryParams={queryParams}
        table={table}
        useQuery={useQuery}
      />
    </Container>
  );
}
