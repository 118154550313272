import { alpha, useTheme } from "@mui/material/styles";
import { useMatch, NavLink as RouterNavLink } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useContext } from "react";
import ViewportSideNavContext from "./ViewportSideNavContext.react";
import CircleIcon from "@mui/icons-material/Circle";
import { PopoverContext } from "./ViewportSideNavItem.react";
import { MenuItem } from "@mui/material";

export default function ViewportSideNavSubItem({
  "data-testid": dataTestID,
  label,
  to,
}) {
  const isViewportNavOpen = useContext(ViewportSideNavContext);
  const isWithinPopover = useContext(PopoverContext);
  const theme = useTheme();
  const isActive = useMatch(to);
  const typeCollapsed = !isViewportNavOpen && {
    fontSize: 10,
    lineHeight: "16px",
    textAlign: "center",
  };

  return isWithinPopover ? (
    <MenuItem
      dense={true}
      component={RouterNavLink}
      to={to}
      sx={[styles.rootMenuItem(theme)]}
    >
      {label}
    </MenuItem>
  ) : (
    <ListItemButton
      data-testid={dataTestID}
      component={RouterNavLink}
      to={to}
      disableGutters
      sx={[styles.root(theme), !isViewportNavOpen && styles.collapsed]}
    >
      <ListItemIcon sx={styles.icon}>
        <CircleIcon sx={{ fontSize: "inherit" }} />
      </ListItemIcon>
      <ListItemText
        primary={label}
        primaryTypographyProps={{
          noWrap: false,
          typography: "body2",
          textTransform: "capitalize",
          fontWeight: isActive ? "fontWeightSemiBold" : "fontWeightMedium",
          ...typeCollapsed,
        }}
      />
    </ListItemButton>
  );
}

const styles = {
  root: (theme) => ({
    borderRadius: "8px",
    color: theme.palette.text.secondary,
    marginBottom: "4px",
    minHeight: "44px",
    padding: "4px 8px 4px 12px",
    "&.active": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.primary.main
          : theme.palette.primary.light,
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      "&:hover": {
        backgroundColor: alpha(theme.palette.primary.main, 0.16),
      },
    },
  }),
  rootMenuItem: (theme) => ({
    color: theme.palette.text.secondary,
    "&.active": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.primary.main
          : theme.palette.primary.light,
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      "&:hover": {
        backgroundColor: alpha(theme.palette.primary.main, 0.16),
      },
    },
  }),
  collapsed: {
    borderRadius: "6px",
    minHeight: "56px",
    padding: "6px 0 0 0",
    flexDirection: "column",
    margin: 0.5,
  },
  icon: {
    fontSize: 6,
    width: 24,
    height: 24,
    alignItems: "center",
    justifyContent: "center",
    minWidth: "auto",
    mr: 2,
  },
  textCollapsed: {
    maxWidth: "100%",
  },
};
