import { useTranslation } from "react-i18next";
import {
  useCaringGroupsQuery,
  ensureCaringGroupsQueryData,
} from "data/queries/queryCaringGroups";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

export async function loader() {
  return await ensureCaringGroupsQueryData();
}

export default function CaringGroupsSelect({
  value,
  inputValue,
  onChange,
  onInputChange,
}) {
  const { t } = useTranslation();
  const label = t("Caring Group");
  const { data: queryData } = useCaringGroupsQuery({ page: 0 }); // TODO: add a query param

  return (
    <Autocomplete
      getOptionLabel={(cg) => cg.lider.name}
      inputValue={inputValue}
      onChange={onChange}
      onInputChange={onInputChange}
      options={queryData?.data ?? []}
      sx={styles.root}
      value={value}
      renderOption={(props, cg) => (
        <li {...props}>
          <Stack>
            <Typography variant="body1" display="block">
              {cg.lider.name}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom={true}>
              {cg.congregacao.name} · {t(cg.departamento)}
            </Typography>
          </Stack>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          inputProps={{
            ...params.inputProps,
            autoComplete: "off",
          }}
        />
      )}
    />
  );
}

const styles = {
  root: {
    mt: 1,
  },
};
