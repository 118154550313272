import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';
import { useTranslation } from "react-i18next";
import { ensureFamiliaCristaReportsQueryData, useFamiliaCristaReportsQuery } from "data/queries/queryFamiliaCristaAllReports";

import IndexPageHeader from "shared/layouts/index/IndexPageHeader.react";
import IndexPageLayout from "shared/layouts/index/IndexPageLayout.react";
import IndexPageTable from "shared/layouts/index/IndexPageTable.react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export async function loader() {
  return await ensureFamiliaCristaReportsQueryData();
}

export default function FamiliaCristaReportsIndexPage() {
  const { t } = useTranslation();
  const { data: report } = useFamiliaCristaReportsQuery({ page: 0 });

  // Process and prepare the data for the chart
  const presenceCounts = report?.data?.reduce((acc, item) => {
    item.relatorioRelations.forEach((relation) => {
      if (relation.situacao === 'PRESENTE') {
        const leaderName = item.familiaCrista.lider.name;
        const date = item.dataReuniao;

        if (!acc[date]) {
          acc[date] = {};
        }
        if (!acc[date][leaderName]) {
          acc[date][leaderName] = 0;
        }
        acc[date][leaderName]++;
      }
    });
    return acc;
  }, {});

  const leaders = new Set();
  const dataset = Object.keys(presenceCounts ?? {}).map(date => {
    const dateData = { month: date };
    Object.keys(presenceCounts[date]).forEach(leader => {
      leaders.add(leader);
      dateData[leader] = presenceCounts[date][leader];
    });
    return dateData;
  }).sort((a, b) => new Date(a.month) - new Date(b.month));

  const series = Array.from(leaders).map(leader => ({
    dataKey: leader,
    label: leader,
    valueFormatter: (value) => `${value}`
  }));

  const chartSetting = {
    yAxis: [{ label: 'Presentes' }],
    height: 300,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: 'translate(0px, 0)',
      },
    },
  };

  return (
    <IndexPageLayout
      useQuery={useFamiliaCristaReportsQuery}
      hasSearch={false}
      header={
        <IndexPageHeader
          heading={t("Christian Family Reports")}
          routeIndex="/familia-crista/reports"
        />
      }
      table={
        <>
          <BarChart
            dataset={dataset}
            xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
            series={series}
            {...chartSetting}
          />
          <IndexPageTable
            headers={[
              t("Congregation"),
              t("Leader"),
              t("Department"),
              t("Date"),
              t("Guests"),
              t("Study ID"),
              t("Offering"),
              t("Tithe"),
              t("Special Offering"),
              t("Mission Offering"),
              t("Songs"),
              t("Healings"),
              t("Miracles"),
              t("Conversions"),
              t("Message"),
              t("Member"),
              t("Status"),
              t("Comment"),
            ]}
            renderRow={({
              id,
              dataReuniao,
              numeroConvidados,
              estudoId,
              oferta,
              dizimo,
              ofertaEspecial,
              ofertaMissao,
              hinos,
              curas,
              milagres,
              conversoes,
              mensagem,
              familiaCrista: { congregacao, lider, departamento },
              relatorioRelations,
            }) => (
              <>
                {relatorioRelations.map(relation => (
                  <TableRow hover key={relation.id} tabIndex={-1}>
                    <TableCell>{congregacao.name}</TableCell>
                    <TableCell>{lider.name}</TableCell>
                    <TableCell>{t(departamento)}</TableCell>
                    <TableCell>{dataReuniao}</TableCell>
                    <TableCell>{numeroConvidados}</TableCell>
                    <TableCell>{estudoId}</TableCell>
                    <TableCell>{relation.oferta}</TableCell>
                    <TableCell>{relation.dizimo}</TableCell>
                    <TableCell>{relation.ofertaEspecial}</TableCell>
                    <TableCell>{relation.ofertaMissao}</TableCell>
                    <TableCell>{hinos}</TableCell>
                    <TableCell>{curas}</TableCell>
                    <TableCell>{milagres}</TableCell>
                    <TableCell>{conversoes}</TableCell>
                    <TableCell>{mensagem}</TableCell>
                    <TableCell>{relation.familiaCristaRelation.pessoa.name}</TableCell>
                    <TableCell>{relation.situacao}</TableCell>
                    <TableCell>{relation.comentario}</TableCell>
                  </TableRow>
                ))}
              </>
            )}
          />
        </>
      }
    />
  );
}
