import { ensurePersonQuery, usePersonQuery } from "data/queries/queryPerson";
import { ensureCongregationQueryData } from "data/queries/queryCongregations";
import { invalidatePeopleQuery } from "data/queries/queryPeople";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import fetch from "data/fetch";
import MemberForm from "./components/MemberForm.react";
import MembersUpdateHeader from "./components/MembersUpdateHeader.react";

export async function loader({ params: { id } }) {
  await ensurePersonQuery(id);
  await ensureCongregationQueryData();
  return null;
}

export default function MemberUpdatePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: queryData } = usePersonQuery({ id });
  const member = queryData?.data;
  const [errorMessage, setErrorMessage] = useState(null);

  // TODO move to mutations
  const onSubmit = async ({
    name,
    email,
    phone,
    congregationID,
    preferredContactMethod,
    birthDate,
    address,
    maritalStatus,
    marriageDate,
    waterBaptismAt,
    spiritBaptismAt,
    creationStatus,
    type,
    originatingChurch,
    churchDepartment,
    spouseId,
    spouseName,
    spouseBirthdate,
    spouseIsMember,
    childrenInfo,
    photo,
    roles,
    documents,
  }) => {
    setErrorMessage(null);

    const familyMembers = [];

    if (spouseName) {
      familyMembers.push({
        id: spouseId,
        name: spouseName,
        birthDate: spouseBirthdate,
        isMember: spouseIsMember,
        type: "SPOUSE",
      });
    }

    const filteredChildrenInfo = childrenInfo.filter(
      (c) => c.name !== "" && c.birthDate !== ""
    );

    if (filteredChildrenInfo.length) {
      filteredChildrenInfo.forEach((child) => (child.type = "CHILD"));
      familyMembers.push(...filteredChildrenInfo);
    }

    let photoFileId;

    if (photo && !photo.id) {
      try {
        const photoData = new FormData();
        photoData.append("file", photo);
        photoData.append("name", photo.name);
        photoData.append("mime", photo.type);

        const photoResponse = await fetch(`/v1/file`, {
          method: "POST",
          body: photoData,
        });

        photoFileId = photoResponse.id;
      } catch (e) {
        setErrorMessage(
          e.apiMessage ??
            "Sorry, there was an error uploading your personal photo."
        );
        return;
      }
    } else if (photo?.file?.id != null) {
      photoFileId = photo.file.id;
    }

    let documentFileIds = [];
    try {
      // TODO: parallelize
      for (const doc of documents) {
        if (!doc.file) {
          continue;
        }

        if (doc.id != null && doc.file.id != null) {
          documentFileIds.push({
            fileId: doc.file.id,
            type: doc.type,
          });
        } else {
          const docData = new FormData();
          docData.append("file", doc.file);
          docData.append("name", doc.file.name);
          docData.append("mime", doc.file.type);

          const docResponse = await fetch(`/v1/file`, {
            method: "POST",
            body: docData,
          });

          documentFileIds.push({
            fileId: docResponse.id,
            type: doc.type,
          });
        }
      }
    } catch (e) {
      setErrorMessage(
        e.apiMessage ?? "Sorry, there was an error uploading your document."
      );
      return;
    }

    try {
      await fetch(`/v1/pessoa`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id,
          name,
          email,
          phone,
          congregacaoId: congregationID,
          prefContact: preferredContactMethod,
          creationStatus,
          birthDate,
          tipo: type,
          originatingChurch,
          departments: churchDepartment,
          roles: roles,
          maritalStatus,
          waterBaptismAt,
          spiritBaptismAt,
          marriageAt: marriageDate,
          address: address,
          familyMembers,
          documents: [
            photoFileId
              ? {
                  fileId: photoFileId,
                  type: "OFFICIAL_PHOTO",
                }
              : null,
          ]
            .concat(documentFileIds)
            .filter(Boolean),
        }),
      });

      invalidatePeopleQuery();
      navigate("/members");
    } catch (e) {
      setErrorMessage(
        e.apiMessage ?? "Sorry, an unexpected error has occurred."
      );
    }
  };

  return (
    <MemberForm
      key={member?.id}
      onSubmit={onSubmit}
      error={errorMessage}
      member={member}
      breadcrumbs={
        <MembersUpdateHeader
          heading={t("Members")}
          label={member?.name ?? "..."}
          routeIndex="/members"
          routeView={`/members/${id}`}
        />
      }
    />
  );
}
