import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

function fetchRegionals(page = 0, filter = "") {
  return fetch(
    `/v1/regional?size=100&index=${page}&q=${encodeURIComponent(filter)}`
  );
}
const getQueryKey = (index = 0, name = "") => ["regional", index, name];

export function useRegionalsQuery({ page = 0, filter = "", ...other }) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchRegionals(page, filter),
  });
}

export function ensureRegionalsQueryData({ page = 0, filter = "" } = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchRegionals(page, filter),
  });
}

export function invalidateRegionalsQuery() {
  return queryClient.invalidateQueries(["regional"]);
}
